<template>
  <v-container fluid>
    <v-alert
      border="bottom"
      colored-border
      color="green accent-4"
      elevation="2"
      v-if="strName != ''"
    > 
    
    <v-row >
      <v-col class="text-left px-2 py-0">
        
        <span class="font-weight-bold">{{strName}}</span>
      </v-col>
      <v-col class="text-center px-2 py-0">
         <v-badge v-if="dataSurat && dataSurat.idmodel != 1" :color="dataSurat.badgeStatus.color" :content="dataSurat.badgeStatus.text"></v-badge>
      </v-col>
      <v-col class="text-right px-2 py-0">
        <span class="font-weight-normal">DOC:&nbsp;</span><span class="font-weight-bold blue--text" v-if="vJenisSurat">{{ vJenisSurat ? vJenisSurat : '-' }}</span>
        <br> 
        &nbsp;
        <v-btn v-if="currRole" small color="purple" @click="showAddForm()">
          <v-icon>mdi-pencil</v-icon>BALAS SURAT
        </v-btn> 
      </v-col>
    </v-row>
    </v-alert>
    
    <v-row>
      <v-col
        class="mx-auto px-3 py-3"
        cols="12"
        md="6"
      >
        <!--<ejs-pdfviewer height="85vH" id="pdfViewer" :serviceUrl="serviceUrl" documentPath="https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf"> </ejs-pdfviewer>-->
        <pdf v-if="pdfRefresh" ref="pdfReader" height="90vH" :url="linkScan" :src="uripdfViewer"/>
        <p style="font-size:10px;">If the file not show, please click here: 
          <a class="green--text" v-if="linkScan" :href="linkScan" target="_BLANK">{{ linkScan }}</a>
          <a class="red--text"  v-else href="#">File NOT Uploaded!</a>
        </p>
      </v-col>
      <v-col
        class="mx-auto"
        cols="12"
        md="6"
      >
        <v-card class="mx-auto px-3 py-3" style="margin: 0;">
          <v-row>
            <v-col cols="12" md="8">
                <table>
                  <tr>
                    <td width="100px;">NO. SURAT</td>
                    <td width="10px;">:</td>
                    <td class="subtitle-2"><span v-if="dataSurat">{{ dataSurat.noSurat ? dataSurat.noSurat : '-' }}</span></td>
                  </tr>
                  <tr>
                    <td>TGL SURAT</td>
                    <td>:</td>
                    <td class="subtitle-2"><span v-if="dataSurat">{{ dataSurat.tglSurat ? dataSurat.tglSurat : '-' | formatDate }}</span></td>
                  </tr>
                  <tr>
                    <td >DARI</td>
                    <td>:</td>
                    <td class="subtitle-2"><span v-if="dataSurat">{{ dataSurat.suratDari ? dataSurat.suratDari : '-' }}</span></td>
                  </tr>
                  <tr>
                    <td>KEPADA</td>
                    <td>:</td>
                    <td class="subtitle-2"><span v-if="dataSurat">{{ dataSurat.tujuanSurat ? dataSurat.tujuanSurat : '-' }}</span></td>
                  </tr>
                  <tr>
                    <td >HAL</td>
                    <td>:</td>
                    <td class="subtitle-2"><span v-if="dataSurat">{{ dataSurat.perihalSurat ? dataSurat.perihalSurat : '-' }}</span></td>
                  </tr>
                </table>
            </v-col>
            <v-col cols="12" md="4">
              <table >
                <tr v-if="vKeamanan == 'Rahasia'">
                  <td width="70px;" ></td>
                  <td width="10px;"></td>
                  <td class="subtitle-2 red--text"><b>R</b></td>
                </tr>
                <tr>
                  <td >SIFAT</td>
                  <td>:</td>
                  <td class=""><span v-if="vSifat">{{ vSifat ? vSifat : '-' }}</span></td>
                </tr>
                </table>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              
              <v-tabs
                background-color="transparent"
                color="basil"
                grow
                v-model="tab"
                show-arrows
                
              >
                <v-tab key="1">
                Disposisi
                </v-tab>
                <v-tab key="2">
                Share
                </v-tab>
                <v-tab key="3">
                Comment
                </v-tab>
                <v-tab key="4">
                File Lampiran
                </v-tab>
               
              </v-tabs>
              
              <v-tabs-items v-model="tab">
                
                <v-tab-item key="1">
                <v-row>
                  <v-col cols="12">
                    <v-btn small :disabled="disabledDispo" color="warning" @click="dialogDispo=true">
                      <v-icon>mdi-plus</v-icon>Kirim Disposisi
                    </v-btn>
                  </v-col>
                  <v-col cols="12">
                    <v-container fluid class="grey lighten-2 px-5 py-3">
                      <v-timeline
                        :align-top="true"
                        :dense="true"
                      >
                        <div v-if="dataDispo.length > 0">
                          <div v-for="(item, n) in dataDispo" :key="n">
                            <v-timeline-item v-if="item.arrTO"
                              :small="true"
                            >
                              <template v-slot:icon>
                                <v-avatar>
                                  <img :src="item.senderavatarURL">
                                </v-avatar>
                              </template>
                              <v-card class="elevation-2">
                                <v-card-title>
                                  <v-col cols="8" class="text-left caption font-weight-medium"><b>{{ item.fromName }}</b> ({{ item.fposname }})</v-col>
                                  <v-col cols="4" class="text-right caption font-weight-medium">{{ item.created_at | formatDatetime }}</v-col>
                                </v-card-title>
                                <v-card-text class="text">    
                                  <ul>
                                    <div v-for="(dsp,i) in item.arrDispo" v-bind:key="i">
                                      <li v-if="dsp != null">
                                        <span class="caption">{{ dsp.nmpetunjukdispo}}</span>
                                      </li>
                                    </div>
                                  </ul>
                                  <v-row>
                                    <v-col cols="12" class="text-left caption font-weight-medium">
                                      <div v-if="item.arrTO && item.hruserpositions_from.id != item.hruserpositions_to.id">
                                        <ul style="list-style-type:none;">
                                          <li v-for="(ito, idxto) in item.arrTO" :key="idxto">
                                            <v-icon>mdi-arrow-right</v-icon>&nbsp;
                                            <b>{{ ito.name }}</b> ({{ ito.toPrefix + ' ' +ito.pos }})
                                          </li>
                                        </ul>
                  
                                      </div>
                                    </v-col>
                                  </v-row>
                                  <div cols="4" class="text-right caption font-weight-medium">Batas Respon: <span v-html="item.vtglRespon"></span></div>
                                  <v-divider></v-divider>
                                  <div class="caption"><span>Notes:</span><br><span class="font-italic subtitle-2">{{ item.subject }}</span></div>
                                </v-card-text>
                              </v-card>
                            </v-timeline-item>
                          </div>
                        </div>
                        <div v-else>
                          NA
                        </div>
                      </v-timeline>
                    
                    </v-container>
                  </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item key="2">
                  <v-row>
                  <v-col cols="12">
                    <v-btn small color="warning" @click="dialogShare=true">
                      <v-icon>mdi-plus</v-icon>Share Surat
                    </v-btn>
                  </v-col>
                  <v-col cols="12">
                    <v-container fluid class="grey lighten-2 px-5 py-3">
                      <v-timeline
                        :align-top="true"
                        :dense="true"
                      >
                        <div v-if="dataShare.length > 0">
                          <div v-for="(item, n) in dataShare" :key="n">
                            <v-timeline-item v-if="item.arrTO"
                              :small="true"
                            >
                              <template v-slot:icon>
                                <v-avatar>
                                  <img :src="item.senderavatarURL">
                                </v-avatar>
                              </template>
                              <v-card class="elevation-2">
                                <v-card-title>
                                  <v-col cols="8" class="text-left caption font-weight-medium"><b>{{ item.fromName }}</b> ({{ item.fposname }})</v-col>
                                  <v-col cols="4" class="text-right caption font-weight-medium">{{ item.created_at | formatDatetime }}</v-col>
                                </v-card-title>
                                <v-card-text class="text">    
                                  <v-row>
                                    <v-col cols="12" class="text-left caption font-weight-medium">
                                      <div v-if="item.arrTO && item.hruserpositions_from.id != item.hruserpositions_to.id">
                                        <ul style="list-style-type:none;">
                                          <li v-for="(ito, idxto) in item.arrTO" :key="idxto">
                                            <v-icon>mdi-arrow-right</v-icon>&nbsp;
                                            <b>{{ ito.name }}</b> ({{ ito.toPrefix + ' ' +ito.pos }})
                                          </li>
                                        </ul>
                  
                                      </div>
                                    </v-col>
                                  </v-row>
                                  
                                  <v-divider></v-divider>
                                  <div class="caption"><span>Notes:</span><br><span class="font-italic subtitle-2">{{ item.subject }}</span></div>
                                </v-card-text>
                              </v-card>
                            </v-timeline-item>
                          </div>
                        </div>
                        <div v-else>
                          NA
                        </div>
                        </v-timeline>
                    
                    </v-container>
                  </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item key="3">
                  <v-row>
                  <v-col cols="12">
                    <v-btn small color="warning" @click="dialogComment=true">
                      <v-icon>mdi-plus</v-icon>Komentar Surat
                    </v-btn>
                  </v-col>
                  <v-col cols="12">
                    <v-container fluid class="grey lighten-2 px-5 py-3">
                      <v-timeline
                        :align-top="true"
                        :dense="true"
                      >
                        <div v-if="dataComment.length > 0">
                          <div v-for="(item, n) in dataComment" :key="n">

                            <div v-if="item.hruserpositions_to">
                              <v-timeline-item v-if="item.arrTO"
                                :small="true"
                              >
                                <template v-slot:icon>
                                  <v-avatar>
                                    <img :src="item.senderavatarURL">
                                  </v-avatar>
                                </template>
                                <v-card class="elevation-2">
                                  <v-card-title>
                                    <v-col cols="8" class="text-left caption font-weight-medium"><b>{{ item.fromName }}</b> ({{ item.fposname }})</v-col>
                                    <v-col cols="4" class="text-right caption font-weight-medium">{{ item.created_at | formatDatetime }}</v-col>
                                  </v-card-title>
                                  <v-card-text class="text">    
                                    <v-row>
                                      <v-col cols="12" class="text-left caption font-weight-medium">
                                        <div v-if="item.arrTO && item.hruserpositions_from.id != item.hruserpositions_to.id">
                                          <ul style="list-style-type:none;">
                                            <li v-for="(ito, idxto) in item.arrTO" :key="idxto">
                                              <span v-if="ito.name != null"><v-icon>mdi-arrow-right</v-icon>&nbsp;
                                              <b>{{ ito.name }}</b> ({{ ito.toPrefix + ' ' +ito.pos }})</span>
                                            </li>
                                          </ul>
                    
                                        </div>
                                      </v-col>
                                    </v-row>
                                    
                                    <v-divider></v-divider>
                                    <div class="caption"><span>Notes:</span><br><span class="font-italic subtitle-2">{{ item.subject }}</span></div>
                                  </v-card-text>
                                </v-card>
                              </v-timeline-item>
                            </div>
                            <div v-else>
                              <v-card class="elevation-2">
                                  <v-card-title>
                                    <v-col cols="8" class="text-left caption font-weight-medium"><b>{{ item.fromName }}</b> ({{ item.fposname }})</v-col>
                                    <v-col cols="4" class="text-right caption font-weight-medium">{{ item.created_at | formatDatetime }}</v-col>
                                  </v-card-title>
                                  <v-card-text class="text">    
                                    <div class="caption"><span>Notes:</span><br><span class="font-italic subtitle-2">{{ item.subject }}</span></div>
                                  </v-card-text>
                                </v-card>
                            </div>
                          </div>
                        </div>
                        <div v-else>
                          NA
                        </div>

                      </v-timeline>
                    
                    </v-container>
                  </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item key="4">
                  <v-row>
                  <v-col cols="12">
                    <v-btn small color="warning" @click="dialogAttachment=true">
                      <v-icon>mdi-plus</v-icon>Upload File Lampiran
                    </v-btn>
                  </v-col>
                  <v-col cols="12">
                    <v-container fluid class="grey lighten-2 px-5 py-3">
                      <div v-if="dataLampiran.length>0">
        
                          <v-data-table
                              :headers="fieldsAttach"
                              :items="dataLampiran"
                              class="elevation-1"
                            >

                            <template v-slot:item.name="{ item }">
                             <a :href="item.url" target="_blank">{{ item.name }}</a>
                            </template>
                            <template v-slot:item.aksi="{ item }">

                              <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                  <span class="pointer" v-on="on" @click="goFileLink(item.url)"><v-icon class="green--text">mdi-eye</v-icon></span>
                                </template>
                                <span>Buka File</span>
                              </v-tooltip>
                              &nbsp;
                              <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                  <span class="pointer" v-on="on" @click="confirmDeleteFile(item.id_ms, item.id)"><v-icon class="red--text">mdi-close</v-icon></span>
                                </template>
                                <span>Hapus File</span>
                              </v-tooltip>
                              
                            </template>
                          </v-data-table>
                      </div>
                      <div v-else>
                        NA
                      </div>
                    </v-container>
                  </v-col>
                  </v-row>
                  
                </v-tab-item>
                
              </v-tabs-items> 
            </v-col>
          </v-row>
        </v-card>
         <div class="text-right font-weight-light font-italic" v-if="dataSurat">
          <span style="line-height:0;font-size:11px">Created: {{ dataSurat.created_at | formatDatetime }}, {{ dataSurat.CreatorName }} ( {{dataSurat.Creatorposname}})</span><br>
          <span style="line-height:0;font-size:11px">Updated: {{ dataSurat.updated_at | formatDatetime }}, {{ dataSurat.EditorName }} ( {{dataSurat.Editorposname}})</span>
        </div>
      </v-col>
    </v-row>



    <v-snackbar
      v-model="toast.show"
      :timeout="toast.timeout"
    >
      {{ toast.text }}
      <v-btn
        :color="toast.color"
        text
        @click="toast.show = false"
      >
        Close
      </v-btn>
    </v-snackbar>

    <v-dialog v-model="isLoading" fullscreen>
          <v-container fluid fill-height style="background-color: rgba(0, 0, 0, 0.8);">
              <v-layout justify-center align-center>
                <v-card
                  width="70vW"
                  class="pa-md-2 mx-lg-auto"
                  outlined
                >
                  <v-list-item three-line centered>
                    <v-list-item-content>
                      <div class="overline mb-4">Processing</div>
                      <v-list-item-title class="headline mb-1">Please Wait....</v-list-item-title>
                      <v-list-item-subtitle>
                        <v-progress-linear
                          indeterminate
                          color="yellow darken-2"
                        ></v-progress-linear>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>

              </v-layout>
          </v-container>
      </v-dialog>

      <v-dialog v-model="delConfirm" persistent max-width="350">
        <v-card>
          <v-card-title class="headline">Konfirmasi</v-card-title>
          <v-card-text>Apakah anda yakin ingin meng-HAPUS data tersebut?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="warning darken-1" text @click="delConfirm = false">Batal</v-btn>
            <v-btn color="blue darken-1" @click="doDeleteData()">Hapus</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogDispo" persistent max-width="80vW">
        <v-card>
          <v-card-title class="headline">Disposisi</v-card-title>
          <v-card-text>
            <form-disposisi :id-letter="idLetter" @dialogDispoClose="closeDialog(1)" />
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogShare" persistent max-width="70vW">
        <v-card>
          <v-card-title class="headline">Share Surat</v-card-title>
          <v-card-text>
            <form-share :id-letter="idLetter" @dialogShareClose="closeDialog(2)" />
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogComment" persistent max-width="70vW">
        <v-card>
          <v-card-title class="headline">Komentar</v-card-title>
          <v-card-text>
            <form-comment :participant="listuposParticipant" :id-letter="idLetter" @dialogCommentClose="closeDialog(3)" />
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogAttachment" persistent max-width="70vW">
        <v-card>
          <v-card-title class="headline">File Lampiran</v-card-title>
          <v-card-text>
            <form-upload :id-letter="idLetter" @dialogAttachmentClose="closeDialog(4)" />
          </v-card-text>
        </v-card>
      </v-dialog>


  </v-container>
</template>

<script>
  import moment from 'moment'
  import pdf from 'vue-pdfjs'

  import secureStorage from '@/utils/secureStorage'
  import { getDataSuratByLetterGUID } from '@/api/datasurat'
  import { getListPetunjukDisposisiActive } from '@/api/petunjukdisposisi'
  import { getSMByID, updateLetterInOut, getViewSuratSMDisposisi, getViewSuratSMTTDSurat, getViewSuratSMShare, getViewSuratSMComment} from '@/api/letterinout'
  import { getMasterAttachment, insertMasterAttachment, uploadMasterAttachment, deleteMasterAttachment, deleteFileMasterAttachment } from '@/api/masterattachment'
  import { _GLOBAL } from '@/api/server'

  import formDisposisi from '../components/formDisposisi'
  import formShare from '../components/formShare'
  import formComment from '../components/formComment'
  import formUpload from '../components/formUpload'

  export default {
    name: 'SuratView1',
    components: { pdf, formDisposisi, formShare, formComment, formUpload },
    props: {
      dSurat: {
        type: Object,
        default: null
      },
      letterguid: {
        type: String,
        default: ''
      },
      modelsurat: {
        type: Number,
        default: 0
      }
    },
    data: () => ({
      tab: 0,
      toast: { show: false, color:'green', text:'', timeout: 2000 },
      isLoading: false,
      dataSurat: {},
      delConfirm: false,
      dialogDispo: false,
      dialogShare: false,
      dialogComment: false,
      dialogAttachment: false,
      pdfRefresh: true,
      currRole: null,
      uripdfViewer: _GLOBAL.PORTALURL, // Development
      // uripdfViewer: _GLOBAL.PORTALURL + '/static', // Productions
      fieldsAttach: [
        {
          text: 'ID',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: 'Name', value: 'name', align: 'center', class:'subtitle-1' },
        { text: 'Uraian', value: 'notes', align: 'center', class:'subtitle-1' },
        { text: 'Aksi', value: 'aksi', align: 'center', class:'subtitle-1'}
      ],
      linkScan:'',
      petunjukDispo: [],
      dataDispo: [],
      dataTTD: [],
      dataComment: [],
      dataShare: [],
      dataLampiran:[],
      posall: [],
      userall: [],
      strName: '',
      idLetter: 0,
      disabledDispo: true,
      listuposParticipant: [],
      del: {id_ms: 0, idData:0},
      vJenisSurat: '',
      vSifat: '',
      vKeamanan: '',

    }),
    provide: {
    },
    filters: {
      formatDate: function (value) {
        if (!value) return ''
        var sdate = moment(value).format('DD MMM YYYY')
        return sdate
      },
      formatDatetime: function (value) {
        if (!value) return ''
        var sdate = moment(value).add(7, 'hours').format('DD/MM/YY HH:mm')
        return sdate
      }
    },
    computed: {
    },
    created () {
      const vm = this
      vm.getPetunjukDispo()

      this.currRole = secureStorage.getItem('currRole')
      this.posall = secureStorage.getItem('posall')
      this.userall = secureStorage.getItem('userall')
      
      console.log("tot posall", this.posall.length)
      vm.dataSurat = vm.dSurat
      this.initDataSurat()
    },
    destroyed () {
    },
    mounted () {
      const vm = this
      // console.log('currRole', this.currRole)
      
      if(this.currRole){
        var myuser = secureStorage.getItem('user')
        var pref = this.currRole.prefix != '' ? this.currRole.prefix + ' ' : ''
        this.strName = myuser.fullname + ' (' + pref + this.currRole.hrposition.pos_name + ')'
        var role = this.currRole.rolename

        if(role === 'kabag' || role==='asisten'  || role==='kadiv'  || role==='manager' || role==='GM' || role==='director' || role==='VP' || role==='presdir' || role==='komite'){
          this.disabledDispo = false
        }
      }

      setTimeout(() => {
        vm.tab = 0
      }, 1000);
      
    },
    methods: {
      goBack () {
        this.$router.go(-2)
      },
      showAddForm () {
        const vm = this 
        vm.$router.push('/case/crlettermn/'+vm.dataSurat.caseGUID)
      },
      goFileLink (uri) {
        var open = window.open(uri,'_blank')
        if (!open || open == null || typeof(open)=='undefined'){
          var errmsg = "Turn off your pop-up blocker!\n\nWe try to open the following url:\n"+uri
          console.log(errmsg)
          vm.$alert(errmsg)
        }
      },
      closeDialog(type){
        const vm = this
        if(type == 1){
          vm.dialogDispo = false
          vm.getDataDisposisi(vm.idLetter)
        } else if(type == 2){
          vm.dialogShare = false
          vm.getDataShare(vm.idLetter)
        } else if(type == 3){
          vm.dialogComment = false
          vm.getDataComment(vm.idLetter)
        } else if(type == 4){
          vm.dialogAttachment = false
          vm.getDataLampiran(vm.idLetter)
        }
      },
      getPetunjukDispo () {
        const vm = this
        vm.isLoading = true
        getListPetunjukDisposisiActive().then(response => {
          var data = response.data
          //// console.log(data)
          vm.petunjukDispo = []
          if(data.length > 0){
           vm.petunjukDispo = data
          }
          
          // console.log(vm.petunjukDispo)
          vm.isLoading = false
        }).catch(err => {
          // console.log(err)
          // vm.$router.push('/401')
          vm.isLoading = false
        })
      },
      colTglBtsRespon: function (value) {
        if (!value) return ''
        var now = moment().format()
        const dateIsAfter = moment().isAfter(moment(value));
        const dateIsSame = moment().isSame(moment(value));
        const dateIsBefore = moment().isBefore(moment(value));

  
        var sdate = moment(value).format('DD MMM YYYY')
        if(dateIsAfter){
          sdate = '<span class="red--text">' + moment(value).format('DD MMM YYYY') + '</span>'
        }else if(dateIsBefore){
          sdate = '<span class="green--text">' + moment(value).format('DD MMM YYYY') + '</span>'
        }else if(dateIsSame){
          sdate = '<span class="blue--text">' + moment(value).format('DD MMM YYYY') + '</span>'
        }

        return sdate
      },
      
      initDataSurat () {
        const vm = this

        var data = vm.dataSurat
        // console.log(data)
        if(data){

          vm.idLetter = data.id
          if(data.eosecuritytype){
            vm.vKeamanan = data.eosecuritytype.security_type
            vm.dataSurat.eosecuritytype = data.eosecuritytype.id
          }
          if(data.eosifatsurat){
            vm.vSifat = data.eosifatsurat.sifat
            vm.dataSurat.eosifatsurat = data.eosifatsurat.id
          }

          vm.dataSurat['idmodel'] = 0
          if(data.eolettertype){
            vm.vJenisSurat = data.eolettertype.type_name
            vm.dataSurat['idmodel'] = parseInt(data.eolettertype.modelsurat)
            vm.dataSurat.eolettertype = data.eolettertype.id
          }

          if(data.hrorganizations){
            vm.dataSurat.hrorganizations = data.hrorganizations.id
          }
          
          if(data.scanSurat){
            var tmp = {linkfile: _GLOBAL.URLBACKEND + data.scanSurat.url}
            var obj = vm.dataSurat.scanSurat
            vm.dataSurat.scanSurat = Object.assign(tmp, obj);
            vm.linkScan = _GLOBAL.URLBACKEND + data.scanSurat.url
          }

          if(data.tglSurat){
            vm.dataSurat.tglSurat = moment(data.tglSurat).format('YYYY-MM-DD')
          }

          if(data.thang){
            vm.dataSurat.thang = data.thang.toString()
          }

          vm.dataSurat['badgeStatus'] = { text:'', color: 'white' , id: 0}
          if(data.statusSurat){
            if(data.statusSurat.id == 11){
              vm.dataSurat['badgeStatus'] = { text: data.statusSurat.status_name, color: 'grey' , id: data.statusSurat.id}
            }else if(data.statusSurat.id == 3){
              vm.dataSurat['badgeStatus'] = { text: data.statusSurat.status_name, color: 'blue', id: data.statusSurat.id}
            }else if(data.statusSurat.id == 4 || data.statusSurat.id == 19){
              vm.dataSurat['badgeStatus'] = { text: data.statusSurat.status_name, color: 'green', id: data.statusSurat.id}
            }else if(data.statusSurat.id == 6){
              vm.dataSurat['badgeStatus'] = { text: data.statusSurat.status_name, color: 'red', id: data.statusSurat.id}
            } else {
              vm.dataSurat['badgeStatus'] = { text: data.statusSurat.status_name, color: 'grey', id: data.statusSurat.id}
            }
          }

          if(vm.dataSurat.hruserpositions_createdBy){
            var arrpos = vm.posall.find(x => x.id === vm.dataSurat.hruserpositions_createdBy.hrposition)
            if(arrpos){
              vm.dataSurat['Creatorposname'] = vm.dataSurat.hruserpositions_createdBy.prefix ? vm.dataSurat.hruserpositions_createdBy.prefix + ' ' + arrpos.pos_name : arrpos.pos_name
              vm.dataSurat['CreatorName'] = '-'
              var arrusr = vm.userall.find(x => x.id === vm.dataSurat.hruserpositions_createdBy.user)
              var url = ''
              if(arrusr){
                vm.dataSurat['CreatorName'] = arrusr.fullname
                url = _GLOBAL.URLBACKEND + '/images/logo_company.png?imageView2/1/w/80/h/80'
                if(arrusr.avatar){
                  url = _GLOBAL.URLBACKEND + arrusr.avatar.url
                }else{
                  url = _GLOBAL.URLBACKEND + '/images/iconman.png?imageView2/1/w/80/h/80'
                  if (arrusr.senderGender == 'Wanita') {
                    url = _GLOBAL.URLBACKEND + '/images/iconwoman.png?imageView2/1/w/80/h/80'
                  }
                }
              }
              vm.dataSurat['CreatoravatarURL'] = url
            }
            
          }

          if(vm.dataSurat.hruserpositions_editedBy){
            var arrpos = vm.posall.find(x => x.id === vm.dataSurat.hruserpositions_editedBy.hrposition)
            if(arrpos){
              vm.dataSurat['Editorposname'] = vm.dataSurat.hruserpositions_editedBy.prefix ? vm.dataSurat.hruserpositions_editedBy.prefix + ' ' + arrpos.pos_name : arrpos.pos_name
              vm.dataSurat['EditorName'] = '-'
              var arrusr = vm.userall.find(x => x.id === vm.dataSurat.hruserpositions_editedBy.user)
              var url = ''
              if(arrusr){
                vm.dataSurat['EditorName'] = arrusr.fullname
                url = _GLOBAL.URLBACKEND + '/images/logo_company.png?imageView2/1/w/80/h/80'
                if(arrusr.avatar){
                  url = _GLOBAL.URLBACKEND + arrusr.avatar.url
                }else{
                  url = _GLOBAL.URLBACKEND + '/images/iconman.png?imageView2/1/w/80/h/80'
                  if (arrusr.senderGender == 'Wanita') {
                    url = _GLOBAL.URLBACKEND + '/images/iconwoman.png?imageView2/1/w/80/h/80'
                  }
                }
              }
              vm.dataSurat['EditoravatarURL'] = url
            }
            
          }

          vm.getDataDisposisi(vm.idLetter)
          vm.getDataShare(vm.idLetter)
          vm.getDataComment(vm.idLetter)
          vm.getDataLampiran(vm.idLetter)
          // console.log(vm.dataSurat)
        }
        
      },
      getDataDisposisi (id_surat) {
        const vm = this
        vm.isLoading = true
        console.log('getDataDisposisi', id_surat)
        getViewSuratSMDisposisi(id_surat).then(response => {
          var rData = response.data
          // console.log(rData)
          vm.dataDispo = []
          if(rData){
            
            var lasttime = ''
            var lastfromid = ''
            var lastfromcreateddate = ''
            var lastnotes = ''
            var lastidx = 0
            var i = 0
            var dataTO = []

            for (i = 0; i < rData.length; i++) {

              rData[i]['arrDispo'] = []
              if(rData[i].disposisi){
                var sDispo = rData[i].disposisi
                var mapdsp = sDispo.split(",").map(Number);
                var ardsp = []
                
                mapdsp.forEach(el => {
                  var tmp = vm.petunjukDispo.find(x => x.id === el)
                  ardsp.push(tmp)
                });
                rData[i]['arrDispo'] = ardsp
              }

              rData[i]['vtglRespon'] = '-'
              if(rData[i].tgl_batas_respon){
                rData[i]['vtglRespon'] = vm.colTglBtsRespon(rData[i].tgl_batas_respon)
              }

              rData[i]['fposname'] = '-'
              if(rData[i].hruserpositions_from){
                var arrpos = vm.posall.find(x => x.id === rData[i].hruserpositions_from.hrposition)
                if(arrpos){
                  rData[i]['fposname'] = rData[i].hruserpositions_from.prefix ? rData[i].hruserpositions_from.prefix + ' ' + arrpos.pos_name : arrpos.pos_name
                  rData[i]['fromName'] = '-'
                  var arrusr = vm.userall.find(x => x.id === rData[i].hruserpositions_from.user)
                  var url = ''
                  if(arrusr){
                    rData[i]['fromName'] = arrusr.fullname
                    url = _GLOBAL.URLBACKEND + '/images/logo_company.png?imageView2/1/w/80/h/80'
                    if(arrusr.avatar){
                      url = _GLOBAL.URLBACKEND + arrusr.avatar.url
                    }else{
                      url = _GLOBAL.URLBACKEND + '/images/iconman.png?imageView2/1/w/80/h/80'
                      if (arrusr.senderGender == 'Wanita') {
                        url = _GLOBAL.URLBACKEND + '/images/iconwoman.png?imageView2/1/w/80/h/80'
                      }
                    }
                  }
                  rData[i]['senderavatarURL'] = url
                  vm.listuposParticipant.push(rData[i].hruserpositions_from.id)
                }
                
              }

              rData[i]['tposname'] = '-'
              if(rData[i].hruserpositions_to && rData[i].hruserpositions_to.id > 0){
                var arrpos = vm.posall.find(x => x.id === rData[i].hruserpositions_to.hrposition)
                if(arrpos){
                  rData[i]['tposname'] = rData[i].prefix ? rData[i].prefix + ' ' + arrpos.pos_name : arrpos.pos_name
                  rData[i]['toName'] = '-'
                  var arrusr = vm.userall.find(x => x.id === rData[i].hruserpositions_to.user)
                  var url = ''
                  if(arrusr){
                    rData[i]['toName'] = arrusr.fullname
                    url = _GLOBAL.URLBACKEND + '/images/logo_company.png?imageView2/1/w/80/h/80'
                    if(arrusr.avatar){
                      url = _GLOBAL.URLBACKEND + arrusr.avatar.url
                    }else{
                      url = _GLOBAL.URLBACKEND + '/images/iconman.png?imageView2/1/w/80/h/80'
                      if (arrusr.senderGender == 'Wanita') {
                        url = _GLOBAL.URLBACKEND + '/images/iconwoman.png?imageView2/1/w/80/h/80'
                      }
                    }
                  }
                  rData[i]['toavatarURL'] = url
                  vm.listuposParticipant.push(rData[i].hruserpositions_to.id)
                }
                
              }

              var creatednow = moment(rData[i]['created_at'])
              var diffdispo = 0
              if(lastfromcreateddate != ''){
                diffdispo = lastfromcreateddate.diff(creatednow, 'minutes')
                // console.log('creatednow: ', diffdispo)
              }

              if (rData[i]['hruserpositions_from'].id == lastfromid && rData[i]['subject'] == lastnotes && diffdispo <= 1) {
                var temp = { pos: rData[i]['tposname'], name: rData[i]['toName'], toavatarURL: rData[i]['toavatarURL'], posID: rData[i]['hruserpositions_to'].hrposition, toPrefix: rData[i]['hruserpositions_to'].prefix }
                dataTO.push(temp)
                rData[lastidx]['arrTO'] = dataTO
              } else {
                dataTO = []
                lastfromid = rData[i]['hruserpositions_from'].id
                lastnotes = rData[i]['subject']
                lastfromcreateddate = moment(rData[i]['created_at'])
                
                lastidx = i

                var temp = { pos: rData[i]['tposname'], name: rData[i]['toName'], toavatarURL: rData[i]['toavatarURL'], posID: rData[i]['hruserpositions_to'].hrposition, toPrefix: rData[i]['hruserpositions_to'].prefix }
                dataTO.push(temp)
                rData[i]['arrTO'] = dataTO
              }
            }

          }
          vm.dataDispo = rData
          // console.log(vm.dataDispo)
          vm.isLoading = false
        }).catch(err => {
          // console.log(err)
          // vm.$router.push('/401')
          vm.isLoading = false
        })
      },

      getDataShare (id_surat) {
        const vm = this
        vm.isLoading = true

        console.log("getDataShare idletter", id_surat)
        getViewSuratSMShare(id_surat).then(response => {
          var rData = response.data
          // console.log("getViewSuratSMShare len", rData.length)
          vm.dataShare = []
          if(rData){
            var lasttime = ''
            var lastfromid = ''
            var lastnotes = ''
            var lastidx = 0
            var i = 0
            var dataTO = []

            for (i = 0; i < rData.length; i++) {

              rData[i]['fposname'] = '-'
              if(rData[i].hruserpositions_from){
                var arrpos = vm.posall.find(x => x.id === rData[i].hruserpositions_from.hrposition)
                if(arrpos){
                  rData[i]['fposname'] = rData[i].hruserpositions_from.prefix ? rData[i].hruserpositions_from.prefix + ' ' + arrpos.pos_name : arrpos.pos_name
                  rData[i]['fromName'] = '-'
                  var arrusr = vm.userall.find(x => x.id === rData[i].hruserpositions_from.user)
                  var url = ''
                  if(arrusr){
                    rData[i]['fromName'] = arrusr.fullname
                    url = _GLOBAL.URLBACKEND + '/images/logo_company.png?imageView2/1/w/80/h/80'
                    if(arrusr.avatar){
                      url = _GLOBAL.URLBACKEND + arrusr.avatar.url
                    }else{
                      url = _GLOBAL.URLBACKEND + '/images/iconman.png?imageView2/1/w/80/h/80'
                      if (arrusr.senderGender == 'Wanita') {
                        url = _GLOBAL.URLBACKEND + '/images/iconwoman.png?imageView2/1/w/80/h/80'
                      }
                    }
                  }
                  rData[i]['senderavatarURL'] = url
                  vm.listuposParticipant.push(rData[i].hruserpositions_from.id)
                }
                
              }

              rData[i]['tposname'] = '-'
              if(rData[i].hruserpositions_to && rData[i].hruserpositions_to.id > 0){
                var arrpos = vm.posall.find(x => x.id === rData[i].hruserpositions_to.hrposition)
                if(arrpos){
                  rData[i]['tposname'] = rData[i].prefix ? rData[i].prefix + ' ' + arrpos.pos_name : arrpos.pos_name
                  rData[i]['toName'] = '-'
                  var arrusr = vm.userall.find(x => x.id === rData[i].hruserpositions_to.user)
                  var url = ''
                  if(arrusr){
                    rData[i]['toName'] = arrusr.fullname
                    url = _GLOBAL.URLBACKEND + '/images/logo_company.png?imageView2/1/w/80/h/80'
                    if(arrusr.avatar){
                      url = _GLOBAL.URLBACKEND + arrusr.avatar.url
                    }else{
                      url = _GLOBAL.URLBACKEND + '/images/iconman.png?imageView2/1/w/80/h/80'
                      if (arrusr.senderGender == 'Wanita') {
                        url = _GLOBAL.URLBACKEND + '/images/iconwoman.png?imageView2/1/w/80/h/80'
                      }
                    }
                  }
                  rData[i]['toavatarURL'] = url
                  vm.listuposParticipant.push(rData[i].hruserpositions_to.id)
                }
              }

              if (rData[i]['hruserpositions_from'].id == lastfromid && rData[i]['subject'] == lastnotes) {
                var temp = { pos: rData[i]['tposname'], name: rData[i]['toName'], toavatarURL: rData[i]['toavatarURL'], posID: rData[i]['hruserpositions_to'].hrposition, toPrefix: rData[i]['hruserpositions_to'].prefix }
                dataTO.push(temp)
                rData[lastidx]['arrTO'] = dataTO
              } else {
                dataTO = []
                lastfromid = rData[i]['hruserpositions_from'].id
                lastnotes = rData[i]['subject']
                lastidx = i

                var temp = { pos: rData[i]['tposname'], name: rData[i]['toName'], toavatarURL: rData[i]['toavatarURL'], posID: rData[i]['hruserpositions_to'].hrposition, toPrefix: rData[i]['hruserpositions_to'].prefix }
                dataTO.push(temp)
                rData[i]['arrTO'] = dataTO
              }
            }
          }
          vm.dataShare = rData
          // console.log("dataShare", vm.dataShare)
          vm.isLoading = false
        }).catch(err => {
          console.log(err)
          // vm.$router.push('/401')
          vm.isLoading = false
        })
      },
      getDataComment (id_surat) {
        const vm = this
        vm.isLoading = true
        console.log("getDataComment")
        getViewSuratSMComment(id_surat).then(response => {
          var rData = response.data
          // console.log(rData)
          vm.dataComment = []
          if(rData){
            var lasttime = ''
            var lastfromid = ''
            var lastnotes = ''
            var lastidx = 0
            var i = 0
            var dataTO = []

            for (i = 0; i < rData.length; i++) {

              rData[i]['fposname'] = '-'
              if(rData[i].hruserpositions_from){
                var arrpos = vm.posall.find(x => x.id === rData[i].hruserpositions_from.hrposition)
                console.log('hruserpositions_from.hrposition.user', arrpos )

                if(arrpos){
                  rData[i]['fposname'] = rData[i].hruserpositions_from.prefix ? rData[i].hruserpositions_from.prefix + ' ' + arrpos.pos_name : arrpos.pos_name
                  rData[i]['fromName'] = '-'
                  var arrusr = vm.userall.find(x => x.id === rData[i].hruserpositions_from.user)
                  // console.log('hruserpositions_from.user', arrusr )
                  var url = ''
                  if(arrusr){
                    rData[i]['fromName'] = arrusr.fullname
                    url = _GLOBAL.URLBACKEND + '/images/logo_company.png?imageView2/1/w/80/h/80'
                    if(arrusr.avatar){
                      url = _GLOBAL.URLBACKEND + arrusr.avatar.url
                    }else{
                      url = _GLOBAL.URLBACKEND + '/images/iconman.png?imageView2/1/w/80/h/80'
                      if (arrusr.senderGender == 'Wanita') {
                        url = _GLOBAL.URLBACKEND + '/images/iconwoman.png?imageView2/1/w/80/h/80'
                      }
                    }
                  }
                  rData[i]['senderavatarURL'] = url
                  vm.listuposParticipant.push(rData[i].hruserpositions_from.id)
                }
                
              }

              rData[i]['tposname'] = '-'
              console.log('hruserpositions_to', rData[i].hruserpositions_to )
              if(rData[i].hruserpositions_to && rData[i].hruserpositions_to.id > 0){
                var arrpos = vm.posall.find(x => x.id === rData[i].hruserpositions_to.hrposition)
                if(arrpos){
                  rData[i]['tposname'] = rData[i].prefix ? rData[i].prefix + ' ' + arrpos.pos_name : arrpos.pos_name
                  rData[i]['toName'] = '-'
                  var arrusr = vm.userall.find(x => x.id === rData[i].hruserpositions_to.user)
                  var url = ''
                  if(arrusr){
                    rData[i]['toName'] = arrusr.fullname
                    url = _GLOBAL.URLBACKEND + '/images/logo_company.png?imageView2/1/w/80/h/80'
                    if(arrusr.avatar){
                      url = _GLOBAL.URLBACKEND + arrusr.avatar.url
                    }else{
                      url = _GLOBAL.URLBACKEND + '/images/iconman.png?imageView2/1/w/80/h/80'
                      if (arrusr.senderGender == 'Wanita') {
                        url = _GLOBAL.URLBACKEND + '/images/iconwoman.png?imageView2/1/w/80/h/80'
                      }
                    }
                  }
                  rData[i]['toavatarURL'] = url
                  vm.listuposParticipant.push(rData[i].hruserpositions_to.id)
                }
                
              }

              if (rData[i]['hruserpositions_from'].id == lastfromid && rData[i]['subject'] == lastnotes) {
                var temp = { pos: rData[i]['tposname'], name: rData[i]['toName'], toavatarURL: rData[i]['toavatarURL'], posID: rData[i]['hruserpositions_to'].hrposition, toPrefix: rData[i]['hruserpositions_to'].prefix }
                dataTO.push(temp)
                rData[lastidx]['arrTO'] = dataTO
              } else {
                dataTO = []
                lastfromid = rData[i]['hruserpositions_from'].id
                lastnotes = rData[i]['subject']
                lastidx = i

                var temp = { pos: rData[i]['tposname'], name: rData[i]['toName'], toavatarURL: rData[i]['toavatarURL'], posID: rData[i]['hruserpositions_to'].hrposition, toPrefix: rData[i]['hruserpositions_to'].prefix }
                dataTO.push(temp)
                rData[i]['arrTO'] = dataTO
              }
            }


          }
          vm.dataComment = rData
          // console.log('dataComment', vm.dataComment)

          vm.isLoading = false
        }).catch(err => {
          console.log(err)
          // vm.$router.push('/401')
          vm.isLoading = false
        })
      },

      getDataLampiran(idLetter) {
        const vm = this
        vm.dataLampiran = []
        vm.isLoading = true
        getMasterAttachment(1, idLetter).then(response => {
          var rData = response.data
          vm.isLoading = false
          var list = []
          if (rData) {
            rData.forEach(element => {
              if (element.attachment != null) {
                var temp = { id_ms: element.id, id: element.attachment.id, url: _GLOBAL.URLBACKEND + element.attachment.url, name: element.attachment.name, notes: element.notes }
                list.push(temp)
              }
            })
          }
          // console.log(list)
          vm.dataLampiran = list
        })
      },
      confirmDeleteFile(id_ms, idData) {
        var vm = this
        
        vm.del.id_ms = id_ms
        vm.del.idData = idData

        vm.delConfirm = true
      },
      doDeleteData(){
        const vm = this
        vm.delConfirm = false
        vm.isLoading = true
        deleteMasterAttachment(vm.del.id_ms).then(response => {
          if (response.status == 200) {
            vm.isLoading = true
            deleteFileMasterAttachment(vm.del.idData).then(response => {
              vm.isLoading = false
              if (response.status == 200) {
                vm.toast = {
                  show: true, color:'green', text: 'File Lampiran BERHASIL terhapus.', timeout: 2000
                }
                vm.getDataLampiran(vm.idLetter)
              } else {
                vm.toast = {
                  show: true, color:'red', text: 'File Lampiran GAGAL terhapus, silakan dicoba kembali.', timeout: 2000
                }
              }
              vm.fetchData(vm.letterGUID)
            }).catch(err => {
              // console.log(err)
              vm.isLoading = false
            })
          } else {
            vm.toast = {
              show: true, color:'red', text: 'File Lampiran GAGAL terhapus, silakan dicoba kembali.', timeout: 2000
            }
          }
        }).catch(function(err){
          // console.log(err)
          vm.isLoading = false
        });
      }
    }
  }
</script>
<style>
.pointer {cursor: pointer;}
.v-card {
    border-radius: 6px;
    /* margin-top: 30px; */
    margin-bottom: 15px;
}
</style>

<style>

</style>

